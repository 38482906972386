/* eslint-disable quotes */
export default {
  save: 'Gem',
  done: 'Færdig',
  dimiss: 'Ignorer',
  cancel: 'Annuller',
  continue: 'Fortsæt',
  select: 'Vælg',
  noData: 'Ingen data fundet',
  selectAnOption: 'Vælg en mulighed',
  welcome: 'Velkommen',
  login: 'Log ind',
  signUp: 'Tilmeld dig',
  signUpSucessTitle: 'Succes!',
  signUpSucessDesc: 'Velkommen til Cykelmakker, du kan nu logge ind',
  signOut: 'Log ud',
  forgotPassword: 'Glemt kodeord',
  forgotPasswordDes: 'Skriv din email herunder',
  forgotSucessTitle: 'Succes!',
  forgotSucessDesc:
    'Vi har sendt en mail med nulstilling af kodeord. Tjek mailen for at ændre dit kodeord.',
  resetPassword: 'Nulstil kodeord',
  backToLogin: 'Tilbage til login',
  signUpTitle: 'Velkommen til Cykelmakker',
  signUpDesc: 'Opret bruger',
  userProfile: 'Brugerprofil',
  editProfile: 'Redigér profil',
  deteleAccount: 'GDPR',
  deteleAccountBtnDes: 'Slet min konto',
  deteleAccountBtn: 'Slet',
  deteleWarningTitle: 'Er du sikker?',
  deteleWarningDesc:
    "Denne handling vil fjerne alle dine data fra app'en permanent og kan ikke fortrydes.",
  deteleWarningDesc2: 'Er du sikker på, at du vil slette din konto?',
  change: 'Ændre',
  email: 'E-mailadresse',
  fullname: 'Fulde navn',
  password: 'Kodeord',
  rePassword: 'Gentag kodeord',
  yourContact: 'Kontaktoplysninger',
  address: 'Adresse',
  addressPlaceholder: 'Skriv en adresse',
  postalCode: 'Postnummer',
  city: 'By',
  country: 'Land',
  phoneNumber: 'Telefonnummer',
  howWeWillContactYou: 'Hvordan vil du kontaktes?',
  language: 'Sprog',
  welcomeBack: 'Velkommen tilbage',
  getStarted: 'Start med at oprette din cykel',
  myBikes: 'Mine cykler',
  myTickets: 'Mine sager',
  registerABike: 'Opret cykel',
  createTicketDesc: 'Fortæl os om dit cykelproblem',
  createATicket: 'Opret sag',
  selectABike: 'Vælg en cykel',
  errorType: 'Fejltype',
  subject: 'Emne',
  description: 'Beskrivelse',
  placeOfService: 'Hvor vil du have foretaget reparationen?',
  repairShop: 'Vælg servicested',
  lastUpdated: 'Sidst opdateret',
  brand: 'Mærke',
  category: 'Kategori',
  year: 'År',
  model: 'Model',
  frameNumber: 'Stelnummer',
  frameNumbersPhoto: 'Billede af stelnummer',
  batteryNumber: 'Batterinummer',
  batteryNumberDescription: 'Batterinummeret står under stegkoden på bagsiden af dit batteri',
  billInformation: 'Fakturaoplysninger',
  number: 'Nummer',
  billNumber: 'Fakturanummer',
  purchasedDate: 'Købsdato',
  purchasedPlace: 'Købssted',
  billsPhoto: 'Billede af kvittering',
  registerBikeWarning:
    'Venligst bekræft at stelnummer, batterinummer (hvis relevant) og købsdato er indtastet korrekt. Et tydelig og klart billede af kvitteringen vil reducere behandlingstiden af sagen.',
  bikeDetails: 'Cykeldetaljer',
  bikeInfo: 'Info om cykel',
  warrantyUntil: 'Garanti til',
  tickets: 'Sager',
  bikeShop: 'Cykelbutik',
  bikeShops: 'Cykelbutikker',
  onlineShops: 'Onlinebutikker',
  overview: 'Overblik',
  ticketInfo: 'Sagsinfo',
  payment: 'Betaling',
  ticketID: 'Sags ID',
  ticketSubject: 'Sagsnummer',
  submittedDate: 'Dato for indsendelse',
  bike: 'Cykel',
  servicePlace: 'Servicested',
  status: 'Status',
  message: 'Besked',
  typeAMessage: 'Skriv en besked',
  messageDescription: 'Besked fra mekaniker eller servicested',
  // Ticket Status Text
  UnSent: 'Ikke sendt',
  NewUnAssigned: 'Ny utildelt',
  Pending: 'Afventer',
  Repairing: 'Under reparation',
  Delayed: 'Forsinket',
  PaymentWaiting: 'Afventer betaling',
  Finished: 'Færdig',
  Returned: 'Udleveret',
  Received: 'Modtaget',
  Closed: 'Afsluttet',
  All: 'Alle',
  AtCustomerSite: 'På hjemmeadressen',
  AtRepairshop: 'Servicested (Hurtigst)',
  languages: {
    da: 'Dansk',
    en: 'Engelsk',
  },
  contactTypes: {
    EmailNotification: 'Email & notifikation',
    Email: 'Email',
    Notification: 'Notifikation',
  },
  changePassword: 'Skift kodeord',
  newPassword: 'Nyt kodeord',
  confirmNewPassword: 'Bekræft nyt kodeord',
  updateProfile: 'Opdater profil',
  photoPermissionBtn: 'Tillad',
  photoPermissionTitle: 'Tillad adgang til fotos',
  photoPermissionDes: 'Registrer informationer om din cykel eller beskriv dit cykelproblem',
  photoPermissionDes2:
    'Tag billeder af cyklens stelnummer, batterinummer (hvis el-cykel) og kvittering',
  photoPermissionDes3: 'Du kan ændre dette i Indstillinger senere',
  notificationPermissionBtn: 'Tillad',
  notificationPermissionTitle: 'Tillad notifikationer',
  notificationPermissionDes:
    'Vælg "tillad" for at modtage notifikationer omkring din cykelsag fra Cykelmakker-teamet',
  batteryNumberPhoto: 'Billede af batterinummer',
  bikePhotos: 'Billede af cyklen',
  orderInfo: 'Ordreinformation',
  orderItem: 'Vare',
  orderQuantity: 'Antal',
  orderAmount: 'Beløb',
  processToPayment: 'Fortsæt til betaling',
  subTotal: 'Subtotal',
  tax: 'Skat',
  total: 'I alt',
  orLoginWith: 'Eller log ind med',
  paid: 'Betalt',
  waitingConfirm: 'Afventer bekræftelse',
  onGoing: 'Igangværende',
  messages: 'Beskeder',
  allTickets: 'Alle sager',
  receivedBikes: 'Modtagede cykler',
  returnBikes: 'Returner cykler',
  xDaysLeft: '{{value}} dage tilbage',
  assignDesc:
    'Ved at trykke på Bekræft knappen vil sagen blive tildelt dig. Hvis du afviser den, vil servicepartneren tildele sagen til en anden.',
  confirm: 'Bekræft',
  decline: 'Afvis',
  declineAssignedCase: 'Afvis tildelt sag',
  delayCase: 'Forsink sagen',
  reason: 'Årsag',
  reasonPlaceholder: 'Skriv årsagen til, hvorfor sagen afvises...',
  ticketActions: 'Sagshandlinger',
  addPayment: 'Tilføj betaling',
  messageToCustomer: 'Besked til kunde (*)',
  messageToServiceProvider: 'Besked til servicepartner (*)',
  Public: 'Offentlig',
  Private: 'Privat',
  search: 'Søg',
  newPurchaseOrder: 'Ny købsordre',
  dueDate: 'Forfaldsdato',
  currency: 'Valuta',
  itemName: 'Varenavn',
  quantity: 'Antal',
  purchaseItems: 'Køb varer',
  newItem: 'Ny vare',
  receivingList: 'Modtag liste',
  receiveBikes: 'Modtag cykler',
  receiveNow: 'Modtag nu',
  deliveryList: 'Aflever liste',
  deliveryBikes: 'Aflever cykler',
  deliveryNow: 'Aflever nu',
  ticket: 'Sag',
  customer: 'Kunde',
  date: 'Dato',
  product: 'Produkt',
  receivedBy: 'Modtaget af',
  note: 'Note',
  notiToCustomer: 'Send notifikation til kunde',
  receipt: 'Kvittering',
  signature: 'Underskrift',
  delivery: 'Levering',
  purchase: 'Køb',
  clear: 'Ryd',
  doNotHaveAccount: 'Har du ikke en konto?',
  profile: 'Profil',
  itemNumber: 'Varenummer',
  notification: 'Notifikation',
  payNow: 'Betal nu',
  time: 'Tid',
  frameNumberError: 'Stelnummer skal starte med WBL',
  viewAndPrint: 'Se og udskriv',
  edit: 'Redigér',
  delete: 'Slet',
  egoRep: 'EgoRep',
  theme: 'Tema',
  dark: 'Mørk',
  light: 'Lys',
  camera: 'Kamera',
  photoLibrary: 'Fotobibliotek',
  saveDraft: 'Gem kladde',
  savedAsDraft: 'Gemt som udkast',
  messageDisplayErrorCaseTitle: 'Kære {{name}}',
  messageDisplayErrorCase:
    'Vi kan med glæde fortælle, at dit display nu er klar til udskiftning. For at få ombyttet displayet hurtigst muligt, har du disse to muligheder:',
  option1: 'Få tilsendt et display og skift det selv (guide medfølger)',
  option2: 'Få en mekaniker til at fikse det',
  messageDisplayErrorCaseDesc:
    'Hvis du vælger den første mulighed, vil du få tilsendt et display med det samme sammen med et gavekort på 200,- til Salling Group.<br/><br/>Tak for din forståelse! ',
  send: 'Sende',
  billPdf: 'Lovforslagets dokument',
  findFrameNumber: 'Hvordan finder jeg stelnummer?',
  normalBike: 'Normal cykel',
  cargoBike: 'Ladcykel',
}
